import axios from 'axios';
//export const BASE_URL = 'https://localhost:7223'; //localhost
//export const BASE_URL = 'https://matruseva-be-test.agsapplications.com'; //test server
export const BASE_URL = 'https://matruseva-be.agsapplications.com'; //production server

export const axiosOpen = axios.create({
    baseURL: BASE_URL
});

export const axiosAuth = axios.create({
    baseURL: BASE_URL,
    headers: { ContentType: 'application/json' },
    withCredentials: true
});
